import React, { useState, useEffect } from "react";
import Divider from "@material-ui/core/Divider";
import { Grid, CircularProgress, Button, TextField, FormHelperText, Link, Typography } from "@material-ui/core";
import { Subtitle3, XSmallHeader } from "../common/CommonStyles/CommonTypographyStyles";
import { images } from "../../assets";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import { Footer } from "../common/Footer";
import Axios from "axios";
import { tokenValidation } from "../../helper/customizedHooks";
import "react-toastify/dist/ReactToastify.css";
import base from "../../config";
import PropTypes from "prop-types";

/**
 * Security page
 * @author poornna.a.ganeshan
 * @version 1.0
 */

const ServerReview = ({email, idToken}) => {

  const [emailAddr, setEmailAddr] = useState("");

  useEffect(() => {
    let timer;
    if (email && idToken) {
      fetchSecurityEmailAddr();
      timer = setInterval(() => {
        const apiCall = () => {
          tokenValidation();
          fetchSecurityEmailAddr();
        };
        apiCall();
      }, 5000);
      return () => clearInterval(timer);
    }
  }, [email, idToken]);

  const fetchSecurityEmailAddr = () => {
    const fetchEmailURL = `${base.urls.apis.doc}/email_id`;

    Axios.get(fetchEmailURL, options).then((res) => {
      setEmailAddr(res.data);
    }).catch((error) => {
      console.log('Error', error)
    })
  }

  const GoToEmail = () => {
    window.location.href = `mailto:${emailAddr}`;
  }

  const options = {
    method: "GET",
    headers: {
      Authorization: idToken,
    },
  };

  return (
    <div>
      <div className="mainDiv">
        <Grid className="headerItems" container>
          <Grid item md={3}>
            <img
              alt=""
              src={images.NavLogoImage}
              style={{ float: "left" }}
            />
          </Grid>
          <Grid item md={9} style={{ paddingTop: "1%", paddingBottom: "1%" }}>
            <XSmallHeader
              className="recomHeader"
              style={{ textAlign: "left", color: "#2e2e2e", fontWeight: "500", float: "right" }}
            >
              Security
            </XSmallHeader>
          </Grid>
        </Grid>
        <Grid>
          <h3 style={{ fontWeight: "500", display: "flex", justifyContent: "end" }}>Learn about Security disclosure and submitting a vulnerability</h3>
        </Grid>
        <Divider />
      </div>
      <div>
        <br></br>
        <div>
          {/* <Typography>Email Contact: {" "}Inquiries related to this can be sent to the <a href="mailto:ProtectingAccenture@accenture.com">Protecting Accenture</a> mailbox.</Typography> */}
          <Typography>Email Contact: {" "}Inquiries related to this can be sent to the
            <Button
              onClick={() => GoToEmail()}
              style={{ fontSize: "1rem", fontFamily: "Graphik", fontWeight: 600, lineHeight: 1.5, textTransform: "capitalize", verticalAlign: "baseline", paddingLeft: "4px", paddingRight: "0px", color: "#7500C0" }}
            >
              Protecting Accenture
            </Button> mailbox.</Typography>
          <Typography>Submit a vulnerability: {" "}<a href="https://accenture.responsibledisclosure.com/hc/en-us/requests/new" target="_blank">https://accenture.responsibledisclosure.com/hc/en-us/requests/new</a></Typography>
          <Typography>Scope and ROE: {" "}<a href="https://accenture.responsibledisclosure.com/hc/en-us/articles/360040090694-Scope-and-ROE" target="_blank">https://accenture.responsibledisclosure.com/hc/en-us/articles/360040090694-Scope-and-ROE</a></Typography>
          <Typography>Acknowledgments: {" "}<a href="https://accenture.responsibledisclosure.com/hc/en-us/articles/360040573233-Acknowledgments" target="_blank">https://accenture.responsibledisclosure.com/hc/en-us/articles/360040573233-Acknowledgments</a></Typography>
          <Typography>Terms of Service: {" "}<a href="https://accenture.responsibledisclosure.com/hc/en-us/articles/360040090874-ResponsibleDisclosure-com-s-Terms-of-Service" target="_blank">https://accenture.responsibledisclosure.com/hc/en-us/articles/360040090874-ResponsibleDisclosure-com-s-Terms-of-Service</a></Typography>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const MSTP = (state) => ({
  email: state.auth.userDetails.email,
  idToken: state.auth.idToken,
});

ServerReview.propTypes = {
  idToken: PropTypes.object,
}

export default connect(MSTP)(ServerReview);